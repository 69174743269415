import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>What is Wifi 6? Purple Cow Internet  💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
        <h1>What is Wifi 6? </h1>
        
          <StaticImage
          layout="constrained"
          src="images/wifi6-Purple-Cow-Internet.png"
          alt="Purple Cow Internet - What is wifi 6?"
          /> 
          <p>WiFi 6, or IEEE 802.11ax, stands as the cutting edge of wireless internet standards, bringing faster speeds, increased device handling, and better performance in crowded areas, among other benefits. For an innovative company like Purple Cow Internet, capitalizing on such advancements is integral to their mission.</p>

          <p>Based in Nova Scotia, Canada, Purple Cow Internet is not just any internet service provider. They're at the vanguard of revolutionizing the Canadian internet landscape. Their ambition is straightforward yet profound: to drive down the overarching cost of internet for everyone. As one of the fastest-growing internet service providers in Canada and with the honor of being the highest-rated based on Google reviews, Purple Cow Internet already boasts a laudable track record.</p>

          <p>Here's how Purple Cow Internet's upcoming utilization of WiFi 6 aligns with their goals and ethos:</p>

          <p>Future-Proofing Connectivity for Canadians: As the internet of things (IoT) and smart homes become mainstream, WiFi 6's superior multi-device handling will be a game-changer. Purple Cow Internet, in deploying WiFi 6, ensures that homes across Nova Scotia, Prince Edward Island, and Newfoundland are future-ready.</p>

          <p>Affordability Meets Cutting-Edge Technology: Purple Cow Internet's ethos revolves around affordability. By adopting WiFi 6 in their offerings, they're proving that cutting-edge doesn't have to mean cutting deep into your pockets. They remain the lowest cost provider while ensuring Canadians get the best in internet technology.</p>

          <p>Enhanced Experience in Crowded Areas: For regions or households experiencing high user density, WiFi 6's prowess in congestion management will be invaluable. Purple Cow Internet recognizes this and is gearing up to deliver an uninterrupted browsing experience.</p>

          <p>A Testament to Putting Customers First: Purple Cow Internet's commitment to go above and beyond for its customers will be further solidified with the integration of WiFi 6. By embracing the latest tech, they're ensuring customers receive top-tier service without the hefty price tag.</p>

          <p>Supporting High-Bandwidth Activities: Whether it's remote work, online education, or high-definition streaming, WiFi 6's capabilities support high-bandwidth activities seamlessly. Purple Cow Internet is ensuring its users can capitalize on these benefits across its service areas.</p>

          <p>As a young and dynamic company, Purple Cow Internet's decision to soon implement WiFi 6 across its products underlines its commitment to deliver unparalleled service while maintaining affordability. By putting their customers first and continuously striving for excellence, they're setting a gold standard for ISPs not just in Canada but potentially the world over.</p>

          <p>
            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>
        </p>

      </section>
    </LayoutNew>
  )
}
